html,
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body{
  min-width: 800px;
}

*,
:after,
:before {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
::selection {
  background: #1890ff;
  color: #fff;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}

input[type="number"],
input[type="password"],
input[type="text"],
textarea {
  -webkit-appearance: none;
}

input:focus,
button:focus,
textarea:focus {
  outline: none !important;
}

dl,
ol,
ul {
  margin: 0;
  padding: 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
  padding: 0;
}

a {
  background-color: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

a:focus {
  text-decoration: none;
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

a:active,
a:hover {
  outline: 0;
  text-decoration: none;
}
img {
  vertical-align: middle;
  border-style: none;
}
textarea {
  overflow: auto;
  resize: none;
}

.noSelect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/
}

.noDrag {
  -webkit-app-region: no-drag !important;
}
