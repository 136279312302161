.page-404 {
  height: 100vh;
  width: 100vw;
  background: url("./images/404.png") no-repeat center center;

  display: flex;
  justify-content: center;
  align-items: center;

  button {
    margin-top: 500px;
  }
}
.my-app {
  .body-gary {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray;
  }
  .ant-empty {
    margin: 250px 0;
  }
  /*表格内容*/
  .user-state-row {
    display: flex;
    padding: 10px;
    .ccol {
      width: 50%;
      display: flex;
      > label {
        min-width: 100px;
        padding: 5px 10px;
        color: #999;
        background-color: #f5f8fa;
      }
      > span {
        flex: 1;
        border: 1px #eee solid;
        padding: 5px 10px;
      }
    }
  }
  /*代码*/
  .html-code {
    padding: 10px;
    overflow-x: auto;
    overflow-y: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    .code {
      white-space: pre-wrap;
      word-wrap: break-word;
    }
  }
  /*弹窗*/
  .minxi-modal-reset {
    .ant-modal-content {
      .ant-modal-body {
        padding: 0;
      }
    }
  }
  /*下拉框*/
  .select-sw-option {
    display: flex;
    justify-content: space-between;
    .s2 {
      color: burlywood;
      padding-right: 5px;
      font-family: Arial;
    }
  }
  /*表格选中*/
  .rowSelectStyle {
    td {
      background-color: rgba(254, 231, 231, 1) !important;
    }
  }
  /*图片上传*/
  .avatar-uploader {
    .ant-upload {
      &.ant-upload-select-picture-card {
        margin: 0;
      }
    }
  }
  /*纵向图*/
  .upload-class-1 {
    .ant-upload {
      height: 130px !important;
      overflow: hidden;
    }
  }
  .upload-class-2 {
    .ant-upload {
      width: 150px !important;
      overflow: hidden;
    }
  }
  .slef-upload-imgs {
    display: flex;
    flex-wrap: wrap;
    .slef-upload-img {
      width: 100px;
      height: 100px;
      overflow: hidden;
      border: 1px dashed #d9d9d9;
      background-color: rgba(0, 0, 0, 0.02);
      margin-right: 8px;
      margin-bottom: 8px;
      position: relative;
      > img {
        width: 100%;
      }
      .del {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        color: #f00;
        justify-content: center;
        cursor: pointer;
      }
      &:hover {
        .del {
          display: flex;
        }
      }
    }
  }
  /*Tabs*/
  .resetTabs .ant-tabs-nav{
    margin: 0;
    .ant-tabs-tab-active{
      background: #f1fae8;
      border-bottom-color: #f1fae8;
    }
  }
  /*表格*/
  .ant-table-wrapper {
    .ant-table-thead {
      > tr > th {
        font-weight: normal;
      }
    }
    .col-word {
      display: block;
      max-width: 150px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  /*按钮组*/
  .ant-radio-button-wrapper {
    em {
      font-style: normal;
      color: #f63;
    }
  }
  /*表单*/
  .ant-form-item {
    &.bottom-12 {
      margin-bottom: 15px !important;
    }
  }
}
.pop-html {
  max-width: 500px;
  word-break: break-all;
  .html-code {
    width: 500px;
    min-height: 100px;
  }
}
.modal-html {
  max-width: 1000px;
  word-break: break-all;
  .html-code {
    max-height: 700px;
    min-height: 700px;
  }
}
.page-parent {
  .page-search {
    margin-bottom: 16px;
    padding: 24px 24px 0;
    background: #fff;
    text-align: right;
    &.one-row {
      padding: 24px;
      align-items: center;
    }
    &.flex-bw {
      display: flex;
      justify-content: space-between;
    }
    &.tabs-bw {
      background: transparent;
      padding: 0;
      .ant-tabs > .ant-tabs-nav {
        margin: 0 !important;
      }
      .card-plan {
        background-color: #fff;
        padding: 24px 0 0 24px;
      }
    }
    .search-actions {
      button {
        margin-left: 10px;
      }
    }
    .action-toggle {
      margin-left: 0 !important;
    }
    .search-form {
      text-align: left;
      width: 100%;
      .ant-radio-group-small .ant-radio-button-wrapper {
        margin: 5px 0 !important;
      }
    }
  }
  .page-content {
    background-color: #fff;
    display: inline-block;
    clear: both;
    width: 100%;
    min-height: calc(100vh - 195px);
    .tab-col-span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &.gray {
        color: #ccc;
      }
    }
    .tab-brack-span {
      display: block;
      word-break: break-all;

      /*下面设置文本内容为单行，超出隐藏并显示三个点*/
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      &.gray {
        color: #ccc;
      }
      &.cursor {
        color: #1890ff;
        cursor: pointer;
        &:hover {
          color: #f00;
        }
      }
    }
    .page-pagination {
      padding: 16px;
      text-align: center;
    }
    .page-tabs {
      .ant-tabs {
        .ant-tabs-nav-list {
          padding: 0 20px;
        }
        .ant-tabs-nav {
          margin: 0;
        }
      }
    }
    .tab-col-span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
