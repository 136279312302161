.main-router {
    position: relative;
    .layout-left {
      width: 230px;
      height: calc(100% - 64px);
      background-color: #fff;
      box-shadow: 0 2px 8px #e5e8ea;
      position: fixed;
      top: 64px;
      left: 0;
      z-index: 1;
      padding: 20px 0;
      overflow-x: hidden;
      overflow-y: auto;
    }
    .layout-header {
      height: 64px;
      width: 100%;
      background-color: #fff;
      box-shadow: 0 2px 8px #e5e8ea;
      position: fixed;
      z-index: 10;
    }
    .layout-content {
      padding-top: 64px;
      padding-left: 230px;
      background-color: #fff;
      .layout-body {
        min-height: calc(100vh - 64px);
        padding: 16px;
        background-color: #f9fcfb;
      }
    }
  }
  
  .page-version {
    min-height: calc(100vh - 120px);
    display: flex;
    justify-content: center;
    align-items: center;
    > span {
      font-size: 22px;
      color: #d0d2d5;
      text-shadow: 1px 2px 3px #e0e2e5;
    }
  }
  