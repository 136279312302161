.login-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;

  .login-form {
    width: 450px;
    height: 380px;
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    > form {
      display: block;
      width: 400px;
      .dybox{
        color: #fff;
      }
    }
    .title{
        text-align: center;
        font-size: 18px;
        color: #fff;
        padding-bottom: 30px;
    }
    .icon{
      height: 100px;
      background: url("/images/icon.png") no-repeat center center;
      background-size: auto 60px;
    }
  }
}
