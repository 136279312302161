.app-header {
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  .logo {
    padding-left: 60px;
    white-space: nowrap;
    font-size: 22px;
    width: 230px;
    height: 60px;
    line-height: 60px;
    position: relative;
    color: #333;
    background: url("../../images/icon.png") no-repeat 10px center / 40px auto;
    &::after {
      content: " ";
      width: 1px;
      height: 20px;
      background-color: #ddd;
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -10px;
    }
  }
  .other {
    flex: 1;
    overflow: hidden;
    display: flex;
    .nav {
      flex: 1;
      display: flex;
      align-items: center;
      padding-left: 20px;
      white-space: nowrap;
      overflow: hidden;
    }
    .cup {
      display: flex;
      white-space: nowrap;
      align-items: center;
    }
    .times {
      white-space: nowrap;
      overflow: hidden;
      padding: 0 24px;
      color: rgba(0, 0, 0, 0.45);
      display: flex;
      align-items: center;
      font-size: 14px;
    }
  }
}
